import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue'

const routes = [
  { path: '/', name: 'home', component: index }, // 直接将根路径指向 index 组件
  { path: '/index', name: 'index', component: index }, // /index 也指向相同的组件
  { path: '/:pathMatch(.*)*', redirect: '/index' } // 其他路径重定向到 /index
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
